console.log('🥑 %cMade by Monk', 'background: #616a2e; color: #f4e9e2; padding: 5px 17px; border-radius: 3px;');
console.log(' %chttp://monk.com.au ', 'padding: 5px 13px;');




jQuery(document).ready(function ($) { 
    
    //menu
    var $hamburger = $(".hamburger");
    $hamburger.on("click", function () {
        $hamburger.toggleClass("is-active"); 
        $("html,body").toggleClass("menu-open");
    });
    //close menu with an outside click (basically anywhere on .site-content)
    $('.site-content').on('click', function() {
        $("html,body").removeClass("menu-open");
        $(".main-navigation").removeClass("toggled");
        $('.sub-menu').removeClass('toggled-on');
        $hamburger.removeClass("is-active"); 
    });
    
    //legal notice
    var $modal = $(".legal"),
        $page = $("#page"),
        $notice = $(".legal-notice"),
        $yes = $("#legal-yes"),
        $no = $("#legal-no");

    $yes.on("click", function () {
        $.cookie('age', 'verified', { expires: 7, path: '/' });
        $modal.fadeOut();
        $("body").css("overflow-y","auto");
    });

    $no.on("click", function () {
        $.cookie('age', 'notverified', { expires: 7, path: '/' });

        $notice.html(
            '<h2>WE’RE SORRY!</h2>You must verify that you are of legal drinking age or older to enter this site.'
        );
    });

    if ( $.cookie('age') !== "verified") {
        $modal.show();
        $modal.addClass('age-notverified');
        // $("body").css("overflow-y","hidden");
        $.removeCookie('age');
    } else {
        $modal.hide();
        $modal.addClass('age-verified');
    }


    if ( $.cookie('visit') !== "recent" ) {
        $.cookie('visit', 'recent', { expires: 7, path: '/' });
        setTimeout(function(){ 
            $("#popup").addClass('show');
            $("body").addClass('overlay');
        }, 15000);
    }



    //add lightbox to image links
    $('a[href]').filter(function () {
        return this.href && this.href.match(/\.(?:jpe?g|gif|bmp|a?png)$/i); 
    }).addClass('lightbox');
    
    
    //activate lightbox
    $('.lightbox').swipebox();
    
    
    //page layout slider
    var $layoutslider = $('.layout-slider');
    
    $layoutslider.slick({
        accessibility: true,
        autoplay: true,
        draggable: true,
        infinite: true,
        pauseOnHover: false,
        swipe: true,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1, 
        prevArrow: $('.slider-prev'),
        nextArrow: $('.slider-next')
    });
    
    
     $(".slider-menu li").click(function(e){
        e.preventDefault();
        // console.log($(this).index());
        
        var slideIndex = $(this).index() / 2; //ignore .divider indexing >.<
      
        $layoutslider.slick('slickGoTo', parseInt(slideIndex) , true);
    });
    
    //feature slider
    $('.shop-slider').slick({
        accessibility: true,
        autoplay: true,
        draggable: true,
        infinite: true,
        pauseOnHover: false,
        swipe: true,
        autoplaySpeed: 5000,
        slidesToShow: 5,
        slidesToScroll: 1,
        prevArrow: $('.prev'), 
        nextArrow: $('.next'),
        responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1
          }
        }
        ]
    });
    
    
    //smoothscroll
    var $links              = $("a").not(".panel a").not(".cat").not(".cat-mini"),
        $page               = $('html, body');
	// Add smooth scrolling to all links
    $links.not('#pagination a').on('click', function (event) {

        // Make sure this.hash has a value before overriding default behavior
        if (this.hash !== "") {
            // Prevent default anchor click behavior
            event.preventDefault();

            // Store hash
            var hash = this.hash;

            // Using jQuery's animate() method to add smooth page scroll
            // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
            $page.animate({
                scrollTop: $(hash).offset().top
            }, 800, function () {

                // Add hash (#) to URL when done scrolling (default click behavior)
                window.location.hash = hash;
            });
        } // End if
    });
    
    //paralax elements
//    $(window).on('scroll', function () {
//        $('.parallax').each(function(){
//            
//            var scrollTop     = $(window).scrollTop(),
//                elementOffset = $(this).offset().top,
//                top           = (elementOffset - scrollTop),
//            
//                offset        = $(window).width > 768 ? -(top * .6) : -(top * .3)
//
//            $(this).css('transform', 'translate3d(0, ' + offset + 'px, 0)');
//        });
//    });
//    
    
    
    
    
    // Wine filtering
    $('.filter-button-group').on( 'click', 'button', function() {
        var filterValue = $(this).attr('data-filter');
        $('.products').isotope({ 
            filter: filterValue,
//            percentPosition: true,
            masonry : {
                columnWidth : ".products li",
                isFitWidth: true
              },
        }); 
    });
    
    

    // change active class on buttons
    var $buttonGroup = $('.button-group');
        $buttonGroup.each( function() {
            
            $buttonGroup.on( 'click', 'button', function() {
                
                var filterValue = $(this).val(),
                    desc = $(this).attr('data-desc');
                
                //remove all active classes
                $buttonGroup.find('.is-checked').removeClass('is-checked');
                
                $( this ).addClass('is-checked'); 
                
                //add active class to buttons with the same value
                $buttonGroup.find( "button[value='"+filterValue+"']" ).addClass('is-checked');
                
                //animate scroll to wine section
                $("html, body").animate({scrollTop: $('#wines-wrapper').offset().top}, 800);
                
                //update section header
                $(".products-title").html( '<h2>' + filterValue + '</h2>' + desc);
                
            });
    });
    
    //affix header
//     var $header = $('#masthead').height(),
//         $intro = $('#page-intro').height(),
//         topoffset = $header + $intro;
// //    console.log($header);
// //    console.log($intro);
//     $('#navbar').affix({
//       offset: {
//         top: function() {
//             return topoffset - 1;
//         },
// //        bottom: function () {
// //          return (this.bottom = $('.footer').outerHeight(true))
// //        }
//         }
//     });
    
    //close popup
    $(".close").on("click", function () {
        $("body").removeClass('overlay');
        $("#popup").fadeOut();
    });
    
    //update val of cart button
    $(".quantity").on("change", "input.qty", function() {
//        console.log($(this).val());
        $('.add_to_cart_button').data("quantity", $(this).val());
    });
    
    //quickview modal
    $(".quick-view-show").on( 'click', function(e) {
        e.preventDefault();
        $( this ).parents("li").children(".quick-view").addClass("show");
        $( "body, html" ).addClass("overlay open");
    });
    $(".quick-view-close").on( 'click', function(e) {
        e.preventDefault();
        $( this ).parents().removeClass("show");
        $( "body,html" ).removeClass("overlay open");
    });
    

    //show comments
    $("#comments").hide();
    $("#show-comments").on("click", function () {
        $( "#comments" ).slideToggle();
    });
    
    //bs events
//    $("body,html").on('affixed.bs.affix', function(){
//        $(this).addClass('mobile');
//    });
//    $("body,html").on('affixed-top.bs.affix', function(){
//        $(this).removeClass('mobile');
//    });
    
//    $('.slidedown').on('touchmove', function(e) {
//        e.preventDefault();
//    }, false);
    
    
    
    
    
    
    $('body').on('click', '#pagination.blog a', function(e){
        
        var $this = $(this);
        
        e.preventDefault();
                
        $this.html('<h4 class="text-center">Loading...</h4>').addClass('loading').loadasync({
            action      : 'replace',
            target      : $this.closest('#pagination')
        }, function(){

            // remove icon

        });
    
    });
    
    $('.gform_wrapper').on('submit', 'form', function(){

	$('[type=submit]', this)        // Select the form's submit button
		.val('Sending...')         // Change the value of the submit button. Change text to whatever you like.
		.prop('disabled', true);    // Not really necessary but will prevent the user from clicking the button again while the form is submitting.
    });    
    
});