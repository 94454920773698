
(function($, $document, undefined){
    "use strict";
    
    var _defaults   = {
            action          : 'inject',
            target          : 'main',
            method          : 'GET',
            data            : {},
            use_body_class  : true,
            use_title       : true,
            callback        : function() {}
        },
        actions     = {
            inject  : 'html',
            append  : 'append',
            replace : 'replaceWith'
        },
        API         = {
            
            load_response    : function(response, status, jqXHR) {

                this.$response  = $(response);
                this.meta       = $.extend(
                    this.options.meta,
                    $.parseJSON(jqXHR.getResponseHeader('X-Monk-Loadasync-Meta')),
                    this.$response.data('loadasync-meta')
                );

                if(status === 'success' && this.$response.length) {

                    this.$target[ actions[this.options.action] || 'html' ](this.$response);
                    
                    this.options.use_body_class && this.meta.body_class && $('body').attr('class', this.meta.body_class);
                    
                    this.options.use_title  && this.meta.title  && $('title').text(this.meta.title);
                    
                }
                
                this.options.callback.apply(this, arguments);
                
            }
            
        },
        loadAsync   = function(url, options) {
            
            this.options    = options;
            this.$target    = $(this.options.target || 'main');
            
            $.ajax({
                url         : url,
                context     : this,
                crossDomain : false,
                dataType    : 'html',
                headers     : {
                    'X-Monk-Load'   : 'async'
                },
                ifModified  : true,
                method      : this.options.method || 'GET',
                statusCode  : {},
                success     : this.load_response,
                data        : this.options.data
            });
            
        };
    
    
    loadAsync.prototype = API;
    
    
    $.fn.loadasync = function(){
        
        var $this       = this.eq(0),
            url         = $this.data('href') || $this.attr('href'),
            url_query   = url.indexOf("?"),
            args        = Array.prototype.slice.call(arguments, 0),
            callback    = typeof args[0] === 'function' ? args[0] : args[1],
            options     = $.extend({}, _defaults, args[0]);
            
        options.callback = callback || _defaults.callback;
        
        if(url_query > -1) {
            
            url             = url.slice(0, url_query);
            options.data    = $.param(options.data) + url.slice(url_query)
            
        }
        
        
        $this.data('monk.loadasync', new loadAsync(url, options)); 

        return this;
        
    }
    
    $.fn.loadasync.Constructor  = loadAsync;
    $.fn.loadasync.defaults     = _defaults;
    
    
})(jQuery, jQuery(document));